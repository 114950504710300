.App-dark {
    color: white;
    background-color: #181818;
    height: 100vh;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.login-image {
    width: 40%;
    height: 100vh;
}

.range-picker {
    width: 200px;
    height: 30px;
    font-weight: bold;
    text-align: left;
    color: #455a64;
    background-color: white;
    border: 0;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(12px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.ag-theme-balham .ag-header {
    background: #fff !important;
}
.ag-theme-balham .ag-filter-toolpanel-header,
.ag-theme-balham .ag-filter-toolpanel-search,
.ag-theme-balham .ag-status-bar,
.ag-theme-balham .ag-header-row,
.ag-theme-balham .ag-multi-filter-group-title-bar {
    color: #000 !important;
}
.ag-theme-balham .ag-column-drop-horizontal {
    border-color: #e2e2e2 !important;
    background-color: #eee !important;
    height: 40px !important;
}
.ag-theme-balham .ag-header-cell {
    border-color: #e2e2e2 !important;
}
.ag-theme-balham .ag-header {
    border-color: #e2e2e2 !important;
}
.ag-header-group-cell-label {
    text-transform: uppercase;
}
.ag-theme-balham input[class^='ag-']:not([type]),
.ag-theme-balham input[class^='ag-'][type='text'],
.ag-theme-balham input[class^='ag-'][type='number'],
.ag-theme-balham input[class^='ag-'][type='tel'],
.ag-theme-balham input[class^='ag-'][type='date'],
.ag-theme-balham input[class^='ag-'][type='datetime-local'],
.ag-theme-balham textarea[class^='ag-'] {
    border: 0px !important;
    border-bottom: 1px solid #d0d0d0 !important;
    padding: 4px 5px !important;
}
.ag-theme-balham .ag-row-hover {
    background-color: #efefef !important;
}
.ag-theme-balham .ag-column-drop-cell-text {
    color: #3e3e3e;
}
.ag-theme-balham .ag-group-title-bar {
    background-color: #000 !important;
}
.ag-theme-balham .ag-ltr .ag-filter-toolpanel-group-title-bar-icon,
.ag-group-title-bar .ag-group-title {
    color: #fff;
}
